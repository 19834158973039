import Vue from 'vue'
import VueRouter from 'vue-router'

import { createNamespacedHelpers } from 'vuex'
import store from '../store'

const requireAuth = () => async (from, to, next) => {
  // page 전환시마다 유저캐쉬 갱신
  const target = {
    id: Math.floor(Math.random() * 1000) + 1,
    avatar: '유저캐쉬변경',
    title: '실시간 유저캐쉬 동기화',
    subtitle: '메세지를 확인해주세요',
    type: 'primary',
    target: 'notificationStore/addNotification',
    route: '/messages',
  }
  await store.dispatch(target.target, target)
  // const payload = {
  //   site: 'GAEGGUL',
  //   userid: JSON.parse(localStorage.getItem('userData')).userid,
  // }
  // await store.dispatch('userStore/UPDATE_USER_CASH', payload)

  // 권한 체크
  const isAuthenticated = store.getters['authStore/isAuthenticated']
  if (isAuthenticated) return next()
  next(`/login?returnPath=${from.fullPath}`)
  // next('/login')
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home-v1.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/sports-prematch',
      name: 'sports-prematch',
      component: () => import('@/views/Sports-Games/Sports-Cross/Sports-Prematch.vue'),
      meta: {
        pageTitle: 'Sports-Prematch',
        breadcrumb: [
          {
            text: 'Sports-Prematch',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/sports-special',
      name: 'sports-special',
      component: () => import('@/views/Sports-Games/Sports-Cross/Sports-Special.vue'),
      meta: {
        pageTitle: 'Sports-Special',
        breadcrumb: [
          {
            text: 'Sports-Special',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/sports-inplay',
      name: 'sports-inplay',
      component: () => import('@/views/Sports-Games/Sports-Cross/Sports-Inplay.vue'),
      meta: {
        pageTitle: 'Sports-Inplay',
        breadcrumb: [
          {
            text: 'Sports-Inplay',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/casino-cards',
      name: 'casino-cards',
      component: () => import('@/views/Casino-Games/Casino-Cards.vue'),
      meta: {
        pageTitle: 'Casino-Cards',
        breadcrumb: [
          {
            text: 'Casino-Cards',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/casino-slot',
      name: 'casino-slot',
      component: () => import('@/views/Casino-Games/Casino-Slot.vue'),
      meta: {
        pageTitle: 'Casino-Slot',
        breadcrumb: [
          {
            text: 'Casino-Slot',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/result',
      name: 'result',
      component: () => import('@/views/Result.vue'),
      meta: {
        pageTitle: 'Result',
        breadcrumb: [
          {
            text: 'Result',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/Faq.vue'),
      meta: {
        pageTitle: 'FAQ',
        breadcrumb: [
          {
            text: 'FAQ',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/notice',
      name: 'notice',
      component: () => import('@/views/Notice.vue'),
      meta: {
        pageTitle: 'Notice',
        breadcrumb: [
          {
            text: 'Notice',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/noticeDetail/:idx',
      name: 'noticeDetail',
      component: () => import('@/views/NoticeDetail.vue'),
      meta: {
        pageTitle: 'Notice',
        breadcrumb: [
          {
            text: 'Notice > NoticeDetail',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/deposit',
      name: 'deposit',
      component: () => import('@/views/Deposit.vue'),
      meta: {
        pageTitle: 'Deposit',
        breadcrumb: [
          {
            text: 'Deposit',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    // {
    //   path: '/grid',
    //   name: 'grid',
    //   component: () => import('@/views/Temp/grid.vue'),
    //   meta: {
    //     pageTitle: 'Grid',
    //     breadcrumb: [
    //       {
    //         text: '그리드',
    //         active: true,
    //       },
    //     ],
    //   },
    //   beforeEnter: requireAuth(),
    // },
    {
      path: '/exchange',
      name: 'exchange',
      component: () => import('@/views/Exchange.vue'),
      meta: {
        pageTitle: 'Exchange',
        breadcrumb: [
          {
            text: 'Exchange',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/pointExchange',
      name: 'pointExchange',
      component: () => import('@/views/PointExchange.vue'),
      meta: {
        pageTitle: 'PointExchange',
        breadcrumb: [
          {
            text: 'PointExchange',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Logout.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/messages',
      name: 'messages',
      component: () => import('@/views/Messages.vue'),
      meta: {
        pageTitle: 'Messages',
        breadcrumb: [
          {
            text: 'Messages',
            active: true,
          },
        ],
      },
      beforeEnter: requireAuth(),
    },

    // Without Auth
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/signup1',
      name: 'signup1',
      component: () => import('@/views/Signup1.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/signup2',
      name: 'signup2',
      component: () => import('@/views/Signup2.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('@/views/ForgotPassword-v1.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
